.section-header {
    border: 1px solid lightgray;
    background-color: #fbfbfb;
    padding: 10px;
    border-radius: 5px;
}

.resume-label {
    display: block;
    text-align: left;
    margin-bottom: 0.5rem;
}
