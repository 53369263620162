body {
    font-family: Roboto, Helvetica, Arial, sans-serif;
}

:root {
    --color-grey: rgba(0, 0, 0, 0.7);
}

/* Hover the text a lightly transparent black. */
.text-hover-black:hover {
    color: #000000b3;
}

/* Labels for input items that don't come out of the box with material design (ex. radio button or checkbox groups */
.input-label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    text-align: left;
}

.bold-header {
    font-weight: bolder;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.form-header {
    font-size: 36px;
    font-weight: 300;
}

/* Small centered hr to highlight a heading */
.centered-hr {
    width: 50px;
    margin: 0 auto; /* Center the hr element */
    border-bottom: 2px solid;
}

/* Styling for selection groups (groups of checkboxes or radio buttons */
.selection-group {
    margin-bottom: 1rem;
}

.button-group-columns-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.button-group-columns-2 .form-check {
    display: flex;
    align-items: center;
}

/* Error message box for when submissions fail for a form. */
.submit-error-message {
    color: #ff0000; /* Bright red color to make the text pop */
    font-weight: bold;
    background-color: #ffe6e6; /* Light red background to highlight the message */
    border: 1px solid #ff0000; /* Border to make the message stand out */
    border-radius: 4px; /* Slight rounding of the corners */
    padding: 10px; /* Add some padding inside the box */
    margin-top: 12px; /* Add some margin at the top for spacing */
}

/* Styling for the submit button on forms. */
.submit-button {
    width: 100%;
    max-width: 120px;
}

/* Padding for pages with a background image. Right now this is just the home page. */
.background-page-padding {
    padding-top: 77px !important;
    padding-bottom: 24px !important;
}

/* Padding for all pages that don't have a background image as the background */
.page-padding {
    padding-top: 110px !important;
    padding-right: 96px !important;
    padding-left: 96px !important;
    padding-bottom: 8px !important;
}

/* The 2 'modal...' styles here are to fix an MDB bug and shouldn't be removed */
.modal-backdrop.fade {
    transition: opacity 0s !important;
}

.modal.fade {
    transition: opacity 0s !important;
}
/* End of bug fix styles */

.modal-header {
    background: #9fa6b2;
    color: white;
}

.modal-body {
    padding: 12px 28px 0px 28px;
}

/* Styling for tablets and larger mobile devices (width less than 768px) */
@media (max-width: 768px) {
    .page-padding {
        padding-top: 110px !important;
        padding-right: 48px !important;
        padding-left: 48px !important;
        padding-bottom: 8px !important;
    }
}

/* Styling for mobile devices (width less than 480px) */
@media (max-width: 480px) {
    .page-padding {
        padding-top: 110px !important;
        padding-right: 24px !important;
        padding-left: 24px !important;
        padding-bottom: 8px !important;
    }

    /* Even for specified 2 column button groups, only show 1 on a small screen. */
    .button-group-columns-2 {
        grid-template-columns: 1fr;
    }
}
