.header-image {
    height: 300px;
    width: 532px;
}

.join-the-movement {
    font-size: 44px;
    font-weight: bold;
    line-height: 1;
}

.sub-heading-text {
    font-size: 14px;
    color: rgb(89, 89, 89);
    line-height: 1;
}
