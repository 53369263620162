.no-jobs-found {
    color: #333; /* Dark gray text color */
    font-weight: 400;
    font-size: 1.2em; /* Slightly larger font size */
    margin-top: 24px;
    margin-bottom: 24px;
    text-align: center;
    border: 2px solid #ccc; /* Light gray border */
    padding: 10px; /* Padding inside the border */
    border-radius: 5px; /* Rounded corners */
    background-color: #f9f9f9; /* Light background color */
}

.pagination-center {
    display: flex;
    justify-content: center;
    width: 100%;
}

.filter-sort-container {
    background-color: #fbfbfb; /* Light grey background */
    border: 1px solid #ddd; /* Light grey border */
    border-radius: 4px; /* Rounded corners */
    padding: 16px; /* Padding inside the container */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Slight elevation effect */
    margin-bottom: 16px; /* Space below the container */
}

.apply-button {
    width: 330px;
}

@media (max-width: 768px) {
    .apply-button {
        width: 100%;
    }
}
