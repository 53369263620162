.about-us-img {
    height: 266px;
}

.about-us-content {
    color: var(--color-grey);
}

/* Padding for all pages that don't have a background image as the background */
.about-us-padding {
    padding-right: 96px !important;
    padding-left: 96px !important;
}

/* Styling for tablets and larger mobile devices (width less than 768px) */
@media (max-width: 768px) {
    .about-us-padding {
        padding-right: 48px !important;
        padding-left: 48px !important;
    }
}

/* Styling for mobile devices (width less than 480px) */
@media (max-width: 480px) {
    .about-us-padding {
        padding-right: 24px !important;
        padding-left: 24px !important;
    }
}
